import React from 'react';
import { useAnnotations } from '../../hooks/useAnnotations';
import AnnotationList from './AnnotationList';
import { AnnotationData } from '../../types/types';

interface AnnotationProps {
  annotations?: AnnotationData[];
  onAnnotationChange?: (annotations: AnnotationData[]) => void;
}

const Annotation: React.FC<AnnotationProps> = ({ annotations, onAnnotationChange }) => {
  const { annotations: internalAnnotations, setAnnotations, isLoading, error } = useAnnotations();

  // Use provided annotations if available, otherwise fallback to internalAnnotations
  const currentAnnotations = annotations || internalAnnotations;
  const setCurrentAnnotations = onAnnotationChange || setAnnotations;

  const updateAnnotations = (updatedAnnotations: AnnotationData[]) => {
    if (typeof setCurrentAnnotations === 'function') {
      setCurrentAnnotations(updatedAnnotations);
    } else {
      console.error('Expected setCurrentAnnotations to be a function');
    }
  };

  const handleEditSave = (id: number, newText: string) => {
    updateAnnotations(
      currentAnnotations.map((annotation) =>
        annotation.id === id ? { ...annotation, text: newText } : annotation,
      ),
    );
  };

  const handleDeleteClick = (id: number) => {
    updateAnnotations(currentAnnotations.filter((annotation) => annotation.id !== id));
  };

  const handleAddAnnotation = (newText: string) => {
    const newAnnotation: AnnotationData = { id: Date.now(), timestamp: '00:00', text: newText };
    updateAnnotations([...currentAnnotations, newAnnotation]);
  };

  if (isLoading && !annotations) {
    return <div className="text-gray-500 text-center py-4">Loading...</div>;
  }

  return (
    <div className="p-4">
      {error && !annotations && <div className="text-red-500 text-center mb-4">{error}</div>}
      <AnnotationList
        annotations={currentAnnotations}
        onEditSave={handleEditSave}
        onDeleteClick={handleDeleteClick}
        onAddAnnotation={handleAddAnnotation}
      />
    </div>
  );
};

export default Annotation;
