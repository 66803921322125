import React, { useState } from 'react';
import { AnnotationData } from '../../types/types';
import TrashIcon from '../../assets/trash3-fill.svg';
import EditIcon from '../../assets/pencil-square.svg';

interface AnnotationItemProps {
  annotation: AnnotationData;
  onEditSave: (id: number, newText: string) => void;
  onDeleteClick: (id: number) => void;
}

const AnnotationItem: React.FC<AnnotationItemProps> = ({
  annotation,
  onEditSave,
  onDeleteClick,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState(annotation.text);

  const handleEditStart = () => {
    setIsEditing(true);
  };

  const handleEditSave = () => {
    if (editText.trim()) {
      onEditSave(annotation.id, editText);
    }
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setEditText(annotation.text);
    setIsEditing(false);
  };

  return (
    <li className="flex justify-between items-center p-4 border-b border-gray-200">
      {isEditing ? (
        <input
          type="text"
          value={editText}
          onChange={(e) => setEditText(e.target.value)}
          onBlur={handleEditSave}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleEditSave();
            if (e.key === 'Escape') handleEditCancel();
          }}
          placeholder="Edit annotation"
          autoFocus
          className="w-full px-2 py-1 border border-gray-300 rounded-md"
        />
      ) : (
        <div>
          <button onClick={() => {}} className="font-bold text-blue-500 mr-2">
            {annotation.timestamp}
          </button>
          : {annotation.text}
        </div>
      )}

      {!isEditing && (
        <div className="flex items-center space-x-2">
          <button onClick={handleEditStart} className="p-1">
            <img src={EditIcon} alt="Edit" className="w-4 h-4" />
          </button>
          <button onClick={() => onDeleteClick(annotation.id)} className="p-1">
            <img src={TrashIcon} alt="Delete" className="w-4 h-4" />
          </button>
        </div>
      )}
    </li>
  );
};

export default AnnotationItem;
