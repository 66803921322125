import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Annotation from '../components/Annotation/Annotation';
import VideoPlayer from '../components/VideoPlayer';
import { createProject } from '../services/projectService';

const CreateNewProjectPage: React.FC = () => {
  const [projectData, setProjectData] = useState<{ title: string; annotations: any[] }>({
    title: 'untitled',
    annotations: [],
  });

  const navigate = useNavigate();

  const handleTitleChange = (newTitle: string) => {
    setProjectData((prev) => ({ ...prev, title: newTitle }));
  };

  const handleAnnotationChange = (annotations: any[]) => {
    setProjectData((prev) => ({ ...prev, annotations }));
  };

  const handleSaveProject = async () => {
    const data = await createProject(projectData);
    if (data && data.id) {
      // Navigate to the new project's page by ID after creation
      navigate(`/project/${data.id}`);
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Header initialTitle={projectData.title} onTitleChange={handleTitleChange} />

      <div className="flex flex-grow">
        <div className="flex items-center justify-center w-1/2 border-r border-gray-300">
          <VideoPlayer />
        </div>

        <div className="flex w-1/2">
          <Annotation
            annotations={projectData.annotations}
            onAnnotationChange={handleAnnotationChange}
          />
        </div>
      </div>

      <button onClick={handleSaveProject} className="text-green-500 p-2 m-4 bg-gray-200 rounded">
        Save Project
      </button>
    </div>
  );
};

export default CreateNewProjectPage;
