// src/hooks/useProjects.ts
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ProjectData } from '../types/types';
import config from '../config';

// Mock data for development when backend is unavailable
const mockProjects: ProjectData[] = [
  {
    id: 1,
    title: 'Mock Project 1',
  },
  {
    id: 2,
    title: 'Mock Project 2',
  },
];

const useProjects = () => {
  const [projects, setProjects] = useState<ProjectData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await axios.get('${config.API_BASE_URL}/projects/home', {
          withCredentials: true,
        });
        setProjects(Array.isArray(data) ? data : []);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError('Backend not available. Displaying mock data.');
        setProjects(mockProjects); // Use mock data when there's an error
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  return { projects, loading, error };
};

export default useProjects;
