import { ProjectData } from '../types/types';

const mockProjects: ProjectData[] = [
  {
    id: -10,
    title: 'Project 1',
    thumbnailURL: 'https://placehold.co/286x286',
  },
  {
    id: -11,
    title: 'Project 2',
    thumbnailURL: 'https://placehold.co/286x286',
  },
];

export default mockProjects;
