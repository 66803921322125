import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Annotation from '../components/Annotation/Annotation';
import VideoPlayer from '../components/VideoPlayer';
import { fetchProject, updateProject, deleteProject } from '../services/projectService';

const ProjectPage: React.FC = () => {
  // Retrieve projectId from the URL using useParams
  const { projectId } = useParams<{ projectId: string }>();
  const [projectData, setProjectData] = useState<{
    title: string;
    videoUrl?: string;
    annotations: any[];
  } | null>(null);

  useEffect(() => {
    const loadProject = async () => {
      if (projectId) {
        const data = await fetchProject(Number(projectId));
        setProjectData(data);
      }
    };
    loadProject();
  }, [projectId]);

  const handleTitleChange = async (newTitle: string) => {
    if (projectId) {
      setProjectData((prev) => (prev ? { ...prev, title: newTitle } : null));
      await updateProject(Number(projectId), { title: newTitle });
    }
  };

  const handleAnnotationChange = async (annotations: any[]) => {
    if (projectId) {
      setProjectData((prev) => (prev ? { ...prev, annotations } : null));
      await updateProject(Number(projectId), { annotations });
    }
  };

  const handleDeleteProject = async () => {
    if (
      window.confirm('Are you sure you want to delete this project? This action cannot be undone.')
    ) {
      if (projectId) {
        await deleteProject(Number(projectId));
        // Redirect to home or user home after deletion
      }
    }
  };

  if (!projectData) return <div>Loading...</div>;

  return (
    <div className="flex flex-col h-screen">
      <Header
        initialTitle={projectData.title}
        projectId={Number(projectId)}
        onTitleChange={handleTitleChange}
      />

      <div className="flex flex-grow">
        <div className="flex items-center justify-center w-1/2 border-r border-gray-300">
          {projectData.videoUrl ? (
            <VideoPlayer projectId={Number(projectId)} url={projectData.videoUrl} />
          ) : (
            <div>No video available.</div>
          )}
        </div>

        <div className="flex w-1/2">
          <Annotation
            annotations={projectData.annotations}
            onAnnotationChange={handleAnnotationChange}
          />
        </div>
      </div>

      <button onClick={handleDeleteProject} className="text-red-500 p-2 m-4 bg-gray-200 rounded">
        Delete Project
      </button>
    </div>
  );
};

export default ProjectPage;
