import axios from 'axios';
import config from '../config';

export const fetchVideoUrl = async (projectId: number): Promise<string | null> => {
  try {
    const response = await axios.get<{ videoUrl: string }>(
      `${config.API_BASE_URL}/projects/${projectId}/video`,
    );
    return response.data.videoUrl;
  } catch (error) {
    console.error('Error fetching video URL:', error);
    return null;
  }
};

export const uploadVideo = async (projectId: number, file: File): Promise<string | null> => {
  const formData = new FormData();
  formData.append('video', file);

  try {
    const response = await axios.post<{ videoUrl: string }>(
      `${config.API_BASE_URL}/projects/${projectId}/upload`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return response.data.videoUrl;
  } catch (error) {
    console.error('Error uploading video:', error);
    return null;
  }
};
