import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { fetchVideoUrl, uploadVideo } from '../services/videoService';

interface VideoPlayerProps {
  projectId?: number;
  url?: string; // Add url as an optional prop
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ projectId, url }) => {
  const [videoUrl, setVideoUrl] = useState<string | null>(url || null);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    // Fetch video URL if this is an existing project and no url is provided
    if (projectId && !url) {
      fetchVideoUrl(projectId).then((fetchedUrl) => setVideoUrl(fetchedUrl));
    }
  }, [projectId, url]);

  const handleVideoUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && projectId) {
      setIsUploading(true);
      const uploadedUrl = await uploadVideo(projectId, file);
      setVideoUrl(uploadedUrl);
      setIsUploading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-full">
      {videoUrl ? (
        <ReactPlayer url={videoUrl} controls width="100%" height="100%" />
      ) : (
        <div className="text-center">
          <label
            htmlFor="video-upload"
            className="bg-blue-500 text-white py-2 px-4 rounded cursor-pointer hover:bg-blue-600"
          >
            {isUploading ? 'Uploading...' : 'Upload Video'}
          </label>
          <input
            id="video-upload"
            type="file"
            accept="video/*"
            onChange={handleVideoUpload}
            className="hidden"
          />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
