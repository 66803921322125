import React, { useState } from 'react';
import CheckIcon from '../../assets/check-square-fill.svg';

interface NewAnnotationItemProps {
  onAddAnnotation: (text: string) => void;
}

const NewAnnotationItem: React.FC<NewAnnotationItemProps> = ({ onAddAnnotation }) => {
  const [newAnnotation, setNewAnnotation] = useState<string>('');

  const handleAddAnnotation = () => {
    if (newAnnotation.trim()) {
      onAddAnnotation(newAnnotation);
      setNewAnnotation('');
    }
  };

  return (
    <li className="flex justify-between items-center p-4 border-b border-gray-200">
      {/* Timestamp Button Placeholder */}
      <button className="text-blue-500 font-bold mr-2">00:00</button>

      {/* Input for New Annotation */}
      <input
        type="text"
        value={newAnnotation}
        onChange={(e) => setNewAnnotation(e.target.value)}
        placeholder="new annotation"
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleAddAnnotation();
        }}
        className="flex-grow px-2 py-1 border border-gray-300 rounded-md mr-2"
      />

      {/* Save Button */}
      <button onClick={handleAddAnnotation} className="p-1">
        <img src={CheckIcon} alt="Save" className="w-4 h-4" />
      </button>
    </li>
  );
};

export default NewAnnotationItem;
