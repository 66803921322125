import React, { useState } from 'react';
import Header from '../components/Header';
import Annotation from '../components/Annotation/Annotation';
import VideoPlayer from '../components/VideoPlayer';

const MockProjectPage: React.FC = () => {
  const [projectData, setProjectData] = useState({
    title: 'Mock Project Title',
    videoUrl: 'https://www.youtube.com/watch?v=G_Lhkhxl8BU',
    annotations: [
      { id: 1, timestamp: '00:15', text: 'Introduction to the topic' },
      { id: 2, timestamp: '01:30', text: 'Main discussion begins' },
      { id: 3, timestamp: '02:45', text: 'Key takeaways' },
    ],
  });

  const handleTitleChange = (newTitle: string) => {
    setProjectData((prev) => ({ ...prev, title: newTitle }));
  };

  const handleAnnotationChange = (annotations: any[]) => {
    setProjectData((prev) => ({ ...prev, annotations }));
  };

  return (
    <div className="flex flex-col h-screen">
      <Header initialTitle={projectData.title} onTitleChange={handleTitleChange} />

      <div className="flex flex-grow">
        <div className="flex items-center justify-center w-1/2 border-r border-gray-300">
          <VideoPlayer url={projectData.videoUrl} />
        </div>

        <div className="flex w-1/2">
          <Annotation
            annotations={projectData.annotations}
            onAnnotationChange={handleAnnotationChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MockProjectPage;
