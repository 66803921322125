import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Signup from './pages/Signup';
import Login from './pages/Login';
import UserHome from './pages/UserHome';
import CreateNewProjectPage from './pages/CreateNewProjectPage';
import ProjectPage from './pages/ProjectPage';
import MockProjectPage from './pages/MockProjectPage';

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/sign-up', element: <Signup /> },
  { path: '/login', element: <Login /> },
  { path: '/user-home', element: <UserHome /> },
  { path: '/create-new', element: <CreateNewProjectPage /> },
  { path: '/project/:projectId', element: <ProjectPage /> },
  { path: '/mock-project', element: <MockProjectPage /> },
]);

export function Routes() {
  return <RouterProvider router={router} />;
}
