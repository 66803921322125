import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config';

interface HeaderProps {
  initialTitle?: string;
  projectId?: number;
  onTitleChange?: (newTitle: string) => void;
}

const Header: React.FC<HeaderProps> = ({ initialTitle = 'untitled', projectId, onTitleChange }) => {
  const [title, setTitle] = useState(initialTitle);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setTitle(initialTitle);
  }, [initialTitle]);

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleTitleSave = async () => {
    setIsEditing(false);
    if (projectId && title.trim()) {
      try {
        await axios.put(`${config.API_BASE_URL}/projects/${projectId}`, { title });
        if (onTitleChange) {
          onTitleChange(title); // Notify parent component of the title change
        }
      } catch (error) {
        console.error('Failed to save title:', error);
      }
    }
  };

  return (
    <div className="bg-blue-600 text-white py-3 mb-3">
      <h1 className="text-center text-xl font-bold">
        {isEditing ? (
          <input
            type="text"
            value={title}
            onChange={handleTitleChange}
            onBlur={handleTitleSave}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleTitleSave();
              if (e.key === 'Escape') setIsEditing(false);
            }}
            autoFocus
            className="text-black px-2 py-1 rounded outline-none"
          />
        ) : (
          <span
            onClick={() => setIsEditing(true)}
            className={`${title === 'untitled' ? 'text-gray-400' : ''} cursor-pointer`}
          >
            {title}
          </span>
        )}
      </h1>
    </div>
  );
};

export default Header;
