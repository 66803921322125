import { useState, useEffect } from 'react';
import axios from 'axios';
import { AnnotationData } from '../types/types';
import { mockAnnotations } from '../data/mockAnnotations';
import config from '../config';

export const useAnnotations = () => {
  const [annotations, setAnnotations] = useState<AnnotationData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadAnnotations = async () => {
      setIsLoading(true); // Ensure loading state resets on each load
      try {
        const response = await axios.get<AnnotationData[]>('${config.API_BASE_URL}/annotations'); // Update to your actual endpoint
        setAnnotations(response.data.length > 0 ? response.data : mockAnnotations);
      } catch (err) {
        console.error('Error loading annotations:', err);
        setError('Failed to load annotations. Using mock data.');
        setAnnotations(mockAnnotations); // Fallback to mock data on error
      } finally {
        setIsLoading(false);
      }
    };

    loadAnnotations();
  }, []);

  return { annotations, setAnnotations, isLoading, error };
};
