import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <section className="text-gray-600 body-font">
      <div className="max-w-5xl pt-52 pb-24 mx-auto">
        <h1 className="text-4xl font-4 font-semibold lh-6 ld-04 pb-11 text-white text-center">
          Footnote
        </h1>
        <h2 className="text-2xl font-4 font-semibold lh-6 ld-04 pb-11 text-whit text-center">
          is a digital platform for dancers and choreographers to notate, visualize, and share
          routines
          <br />
          with precision and ease, transforming the way choreography is documented and communicated.
        </h2>
        <div className="ml-6 text-center">
          <a
            className="inline-flex items-center py-3 font-semibold text-black transition duration-500 ease-in-out transform bg-white px-7 text-md md:mt-0 hover:text-black hover:bg-white focus:shadow-outline"
            href="/"
          >
            <div className="flex text-lg">
              <Link to="/sign-up">
                <span className="justify-center">Sign Up Now!</span>
              </Link>
            </div>
          </a>
          <a
            className="inline-flex items-center py-3 font-semibold tracking-tighter text-white transition duration-500 ease-in-out transform bg-transparent ml-11 bg-gradient-to-r from-blue-500 to-blue-800 px-14 text-md md:mt-0 focus:shadow-outline"
            href="/"
          >
            <div className="flex text-lg">
              <Link to="/login">
                <span className="justify-center">Log In</span>
              </Link>
            </div>
          </a>
        </div>
      </div>
      <div className="pt-12 pb-24 max-w-4xl mx-auto fsac4 md:px-1 px-3">
        {/* Repeat for other sections */}
      </div>
    </section>
  );
}
export default HomePage;
