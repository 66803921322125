import { useNavigate } from 'react-router-dom';
import useProjects from '../hooks/useProjects';
import ProjectCard from '../components/ProjectCard';
import { ProjectData } from '../types/types';
import mockProjects from '../data/mockProjects';

function UserHome() {
  const navigate = useNavigate();
  const { projects, loading, error } = useProjects();

  const newProject: ProjectData = {
    id: -1,
    title: 'Create a New Project',
    thumbnailURL:
      'https://cdn.discordapp.com/attachments/1264335829665448039/1300599343371391018/file-hu0jIFPQTu4pGA4RKhIGQqCY.png?ex=67216d07&is=67201b87&hm=b3503a7e15a556693dce560d57da88ea67e125791855aa812570a7bc96bca2c4&',
  };

  const handleCreateNewProject = () => navigate('/create-new');

  if (loading) return <div>Loading...</div>;

  const projectsToDisplay = error ? mockProjects : projects;

  return (
    <section id="homepage" className="bg-gray-100 py-12">
      <div className="container mx-auto">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-800">Projects Home</h1>
        </div>

        {error && (
          <div className="text-center text-red-500 mb-4">
            Backend not available. Displaying mock data.
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Create New Project Card */}
          <ProjectCard
            project={newProject}
            buttonText="Create New Project"
            onButtonClick={handleCreateNewProject}
          />

          {/* Display either projects or mockProjects based on backend error */}
          {projectsToDisplay.length > 0 ? (
            projectsToDisplay.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                buttonText={error ? 'View Mock Project' : 'View Project'}
                onButtonClick={() =>
                  error ? navigate('/mock-project') : navigate(`/projects/${project.id}`)
                }
              />
            ))
          ) : (
            <div className="text-center text-gray-500 mt-4 col-span-full">
              No projects available. Start by creating a new project!
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default UserHome;
