import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/axiosInstance';
import SignupForm from '../components/SignUp/Login/SignupForm';

function Signup() {
  const navigate = useNavigate();

  const handleSignup = async (email: string, password: string) => {
    try {
      await axiosInstance.post('/auth/signup', { email, password });
      navigate('/userhome');
    } catch (error) {
      console.error('Signup failed:', error);
    }
  };

  const checkEmailExists = async (email: string): Promise<boolean> => {
    try {
      const response = await axiosInstance.post('/auth/check-email', { email });
      return response.data.exists;
    } catch (error) {
      console.error('Email check failed:', error);
      return false;
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl/9 font-bold tracking-tight text-white">
          Create Your New Footnote Account!
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <SignupForm onSubmit={handleSignup} checkEmailExists={checkEmailExists} />
      </div>
    </div>
  );
}

export default Signup;
