import axios from 'axios';
import config from '../config';

const axiosInstance = axios.create({
  baseURL: '${config.API_BASE_URL}/api', //backend URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Interceptor to include JWT token in headers
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
