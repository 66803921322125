import axios from 'axios';
import config from '../config';

export const fetchProject = async (projectId: number) => {
  try {
    const response = await axios.get(`${config.API_BASE_URL}/projects/${projectId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch project:', error);
    return null;
  }
};

export const updateProject = async (
  projectId: number,
  data: { title?: string; annotations?: any[] },
) => {
  try {
    await axios.put(`${config.API_BASE_URL}/projects/${projectId}`, data);
  } catch (error) {
    console.error('Failed to update project:', error);
  }
};

export const deleteProject = async (projectId: number) => {
  try {
    await axios.delete(`${config.API_BASE_URL}/projects/${projectId}`);
  } catch (error) {
    console.error('Failed to delete project:', error);
  }
};

export const createProject = async (data: { title: string; annotations?: any[] }) => {
  try {
    const response = await axios.post(`${config.API_BASE_URL}/projects`, data);
    return response.data;
  } catch (error) {
    console.error('Failed to create project:', error);
    return null;
  }
};
